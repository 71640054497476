var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-content',[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"loginDiv"},[_c('div',{staticClass:"div"},[_c('div',{staticClass:"logos"},[_c('div',[_c('img',{staticClass:"zimg",attrs:{"src":_vm.baseURL+'/public/logo.png'}})]),_c('div',[_c('p',{staticClass:"ptitle"},[_c('span',{staticClass:"lo"},[_vm._v("Auto")]),_vm._v("it")])]),_c('p',{staticClass:"p2"},[_vm._v("Workflows made simple !")])]),_c('div',{staticClass:"formDiv"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.login}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'userName',
                  {
                    rules: [
                      { required: true, message: 'Account (mailbox) must not be empty' },
                      { min: 2, message: 'The length of the account (mailbox) must not be less than 2 bits' },
                      { max: 50, message: 'The length of the account (mailbox) cannot exceed 50 bits' },
                    ],
                  },
                ]),expression:"[\n                  'userName',\n                  {\n                    rules: [\n                      { required: true, message: 'Account (mailbox) must not be empty' },\n                      { min: 2, message: 'The length of the account (mailbox) must not be less than 2 bits' },\n                      { max: 50, message: 'The length of the account (mailbox) cannot exceed 50 bits' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter the account name or mailbox"}},[_c('a-icon',{staticStyle:{"color":"#a9a9a9"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      { required: true, message: 'The password should not be empty' },
                      { min: 8, message: 'Please enter the 8-16-bit password' },
                      { max: 16, message: 'Please enter the 8-16-bit password' },
                    ],
                  },
                ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      { required: true, message: 'The password should not be empty' },\n                      { min: 8, message: 'Please enter the 8-16-bit password' },\n                      { max: 16, message: 'Please enter the 8-16-bit password' },\n                    ],\n                  },\n                ]"}],attrs:{"type":"password","placeholder":"Please enter the password"}},[_c('a-icon',{staticStyle:{"color":"#a9a9a9"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("Log in")])],1)],1)],1),_c('div',{staticClass:"footer"},[_c('span',[_vm._v("Copyright © 2024 "),_c('a',{staticStyle:{"color":"#4094f3"},attrs:{"href":"https://autoit.app"}},[_vm._v("AutoIt")]),_vm._v(" All rights reserved.")])])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }